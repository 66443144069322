import "normalize.css"
import React from "react"
import ReactDOM from "react-dom/client"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { HelmetProvider } from "react-helmet-async"
import { BrowserRouter as Router } from "react-router-dom"
import "styles/index.scss"

import App from "./App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
      <HelmetProvider>
        <Router>
          <App />
        </Router>
      </HelmetProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
