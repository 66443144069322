import React, { createContext, useState } from "react"

export const globalContext = createContext()

function GlobalContextProvider({ children }) {
  const [global, setGlobal] = useState({
    item: "whatever this needs to be",
  })
  const [another, setAnother] = useState({
    item: "We can have multiple states",
  })

  const [siteTitle] = useState("Billy Bass")

  const value = React.useMemo(() => ({
    global,
    setGlobal,
    another,
    setAnother,
    siteTitle,
  }))

  return (
    <globalContext.Provider value={value}>{children}</globalContext.Provider>
  )
}

export default GlobalContextProvider
