import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import React, { useCallback, useEffect, useState } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { Route, Routes, useLocation } from "react-router-dom"

import $log from "scripts/lib/log"

import GlobalContextProvider from "providers/GlobalContextProvider"

/* Importing with React.lazy adds basic code-splitting */
const Home = React.lazy(() => import("views/Home"))
const Spin = React.lazy(() => import("views/Spin"))
const Experience = React.lazy(() => import("views/Experience"))
const Share = React.lazy(() => import("views/Share"))
const NotFound = React.lazy(() => import("views/NotFound"))

function App() {
  const location = useLocation()
  const [token, setToken] = useState()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Recaptcha: token,
      },
    }
  })
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  })
  const [apolloClient, setApolloClient] = useState(
    new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    }),
  )

  useEffect(() => {
    setApolloClient(
      new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
      }),
    )
  }, [token])

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      $log("Execute recaptcha not yet available")
      return
    }
    const token = await executeRecaptcha()
    setToken(token)
  }, [executeRecaptcha])

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      // ReactGA.initialize("G-xxxxxxxxxx")
      $log("DEVELOPMENT")
    } else {
      // ReactGA.initialize("G-xxxxxxxxxx")
      $log("PRODUCTION")
    }
  }, [])

  /* Send GA events on route change */
  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: location.pathname })
  }, [location])

  return (
    <main className="App">
      <React.Suspense fallback="Loading">
        <ApolloProvider client={apolloClient}>
          <GlobalContextProvider>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/spin" element={<Spin />} />
              <Route exact path="/experience" element={<Experience />} />
              <Route exact path="/share" element={<Share />} />
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </GlobalContextProvider>
        </ApolloProvider>
      </React.Suspense>
    </main>
  )
}

export default App
